import React, {useCallback, useState} from 'react';
import {Box, List, ListItem, useMediaQuery, useTheme} from '@material-ui/core';
import {Loading, UploadedItem} from '../../../index';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {
    getAllAttachmentsMetaData,
    getAttachmentsMetaData,
    selectAllAttachmentsMetaData,
} from '../../../../store/appointmentAttachmentsSlice';
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../hooks/customReduxHooks';
import useStyles from './DropzoneStyles';
import useStylesCommon from '../common/AdditionalDetailsCommonStyles';
import {useUserCheck} from '../../../../hooks/useUserCheck';
import {
    useDownloadAsync,
    useHandleRemoveAsync,
    useHandleUploadAsync,
} from '../../../../hooks/appointmentFileUpload';
import {getFileIcon} from '../../../../utils/file';
import useOnDropRejectMessage from '../../../../hooks/useOnDropRejectMessage';
import {CustomDropzone} from '../../../common/inputs';
import {AttachmentTypeEnum} from '../../../../types/appointments';

interface Props {
    appointmentId: string;
    maxFiles?: number;
    className?: string;
    attachmentType?: AttachmentTypeEnum;
    withoutListWrapperStyles?: boolean;
}

const Dropzone = ({
    appointmentId,
    maxFiles,
    className,
    attachmentType,
    withoutListWrapperStyles,
}: Props) => {
    const [uploadLoading, setUploadLoading] = useState(false);
    const [removeFileLoading, setRemoveFileLoading] = useState(false);

    const classes = useStyles();
    const classesCommon = useStylesCommon();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const {t: tCommon} = useTranslation(i18nNamespaces.COMMON);
    const dispatch = useAppDispatch();

    const allAttachmentsMetaData = useAppSelector(selectAllAttachmentsMetaData);
    const attachmentTypeMetaData = allAttachmentsMetaData.filter(data =>
        attachmentType
            ? data.attachmentType === attachmentType
            : data.attachmentType === null,
    );
    const isLoggedUser = useUserCheck();
    const handleUploadAsync = useHandleUploadAsync();
    const downloadFileAsync = useDownloadAsync();
    const removeFileAsync = useHandleRemoveAsync();

    const loading = uploadLoading || removeFileLoading;

    const handleUpload = useCallback(
        async (files: File[]) => {
            setUploadLoading(true);
            await handleUploadAsync(appointmentId, files, attachmentType);
            dispatch(
                getAllAttachmentsMetaData({appointmentId, fetchSilently: true}),
            );
            setUploadLoading(false);
        },
        [appointmentId],
    );

    const handleFileRemove = async (attachmentMetaDataId: string) => {
        setRemoveFileLoading(true);
        await removeFileAsync(appointmentId, attachmentMetaDataId);
        dispatch(
            getAllAttachmentsMetaData({appointmentId, fetchSilently: true}),
        );
        setRemoveFileLoading(false);
    };

    const handleOnDropRejectMessage = useOnDropRejectMessage();

    return (
        <Box className={className}>
            {(!maxFiles || attachmentTypeMetaData.length < maxFiles) && (
                <CustomDropzone
                    text={
                        isDesktop
                            ? tCommon('dragDropAttachment')
                            : tCommon('dragDropAttachmentMobile')
                    }
                    getDropRejectMessage={handleOnDropRejectMessage}
                    onAdd={handleUpload}
                />
            )}
            <Box className={clsx({[classes.listWrapper]: !withoutListWrapperStyles})}>
                {attachmentTypeMetaData && attachmentTypeMetaData.length > 0 && (
                    <List className={classesCommon.filesList}>
                        {attachmentTypeMetaData.map((attachmentMetaData, i) => (
                            <ListItem
                                className={clsx(classesCommon.filesListItem, {
                                    [classesCommon.filesListItemNext]: i > 0,
                                })}
                                onClick={() =>
                                    downloadFileAsync(
                                        appointmentId,
                                        `${attachmentMetaData.id}`,
                                    )
                                }
                                key={i}
                            >
                                <UploadedItem
                                    fileName={attachmentMetaData.fileName}
                                    fileOwner={attachmentMetaData.person}
                                    fileIcon={getFileIcon(
                                        attachmentMetaData.contentType,
                                        attachmentMetaData.thumbnail,
                                    )}
                                    fileDateCreated={attachmentMetaData.uploadDate.toFormat(
                                        "dd'.'MM'.'yyyy",
                                    )}
                                    fileTimeCreated={attachmentMetaData.uploadDate.toFormat(
                                        "HH':'mm",
                                    )}
                                    removeFileEnabled={isLoggedUser(
                                        attachmentMetaData.personId,
                                    )}
                                    removeFileHandler={() =>
                                        handleFileRemove(
                                            `${attachmentMetaData.id}`,
                                        )
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
            </Box>
            <Loading loading={loading} withBackdrop />
        </Box>
    );
};

export default Dropzone;

import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {Logo} from '../../../assets';
import useLandingPageUrl from '../../../hooks/useLandingPageUrl';

import configuration from "../../../configuration";
import {APPOINTMENT_SEARCH_WIDGET_PAGE} from "../../../const/routes";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logo: {
            display: 'block',
            [theme.breakpoints.down('sm')]: {
                width: 130,
            },
        },
    }),
);

const AppLogo = () => {
    const classes = useStyles();
    const {getCurrentUrl} = useLandingPageUrl();
    const isDevOrTest = configuration?.environment === 'Development' || configuration?.environment === 'Test';

    const handleClick = () => {
        window.location.href = isDevOrTest ? APPOINTMENT_SEARCH_WIDGET_PAGE : getCurrentUrl();
    }

    return (
        <img
            alt="Global TeleClinic Logo"
            src={Logo}
            className={classes.logo}
            onClick={handleClick}
            style={{cursor: 'pointer'}}
        />
    );
};

export default AppLogo;

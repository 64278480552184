import useStyles from './OccupationalMedicineDetailsStyles';
import useStylesCommon from '../common/AdditionalDetailsCommonStyles';
import {useTranslation} from 'react-i18next';
import i18nNamespaces from '../../../../const/i18nNamespaces';
import {Box, Typography, useTheme} from '@material-ui/core';
import {Appointment, AttachmentTypeEnum} from '../../../../types/appointments';
import React, {useEffect, useState} from 'react';
import Dropzone from '../Dropzone/Dropzone';
import {useAppSelector} from '../../../../hooks/customReduxHooks';
import {Loading} from '../../../index';
import {Link} from 'react-router-dom';
import {appointmentsApi} from '../../../../api/appointments/appointmentsApi';
import {
    selectIsOMHealthDeclarationInAttachments,
    selectIsOMReferralInAttachments,
} from '../../../../store/appointmentAttachmentsSlice';
import {CheckCircleOutlined, ErrorOutline} from '@material-ui/icons';
import {GRAY} from "../../../../const/colors";

interface Props {
    appointment: Appointment;
}

const OccupationalMedicineDetails = ({appointment}: Props) => {
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation(i18nNamespaces.APPOINTMENT_DETAILS);
    const classes = useStyles();
    const classesCommon = useStylesCommon();
    const [
        isPreventiveExaminationCardFilled,
        setIsPreventiveExaminationCardFilled,
    ] = useState<boolean>(false);
    const theme = useTheme();
    const isOMReferralInAttachments = useAppSelector(
        selectIsOMReferralInAttachments,
    );
    const isOMHealthDeclarationInAttachments = useAppSelector(
        selectIsOMHealthDeclarationInAttachments,
    );

    const fetchOccupationalMedicineState = async (appointmentId: string) => {
        try {
            setLoading(true);
            const {data} = await appointmentsApi.getOccupationalMedicineState(
                appointmentId,
            );
            setIsPreventiveExaminationCardFilled(data?.filledByPatient);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOccupationalMedicineState(appointment?.id);
    }, []);

    const getOccupationalMedicineState = () =>
        fetchOccupationalMedicineState(appointment?.id);

    useEffect(() => {
        getOccupationalMedicineState();
        // Fetch prescriptions on tab focus
        window.addEventListener('focus', getOccupationalMedicineState);

        return () => {
            window.removeEventListener('focus', getOccupationalMedicineState);
        };
    }, []);

    const renderFillMark = (filled: boolean) =>
        filled ? (
            <CheckCircleOutlined
                style={{color: theme.palette.success.main, marginLeft: 8}}
            />
        ) : (
            <ErrorOutline
                style={{color: theme.palette.error.main, marginLeft: 8}}
            />
        );

    return (
        <>
            <h4 className={classesCommon.detailsHeader}>
                {t('documentationNecessaryToOccupationalMedicine')}
            </h4>
            <ul className={classes.listWrapper}>
                <li>
                    <Box style={{display: 'flex', alignItems: 'center'}}>
                        1. {t('referralForTestsFromTheEmployer')}
                        {renderFillMark(isOMReferralInAttachments)}
                    </Box>
                    <Dropzone
                        className={classes.dropzone}
                        appointmentId={appointment.id}
                        attachmentType={AttachmentTypeEnum.OMReferral}
                    />
                </li>
                <li>
                    <Box style={{display: 'flex', alignItems: 'center'}}>
                        2. {t('healthQuestionnaire')}
                        {renderFillMark(isOMHealthDeclarationInAttachments)}
                    </Box>
                    <Dropzone
                        className={classes.dropzone}
                        appointmentId={appointment.id}
                        attachmentType={AttachmentTypeEnum.OMHealthDeclaration}
                    />
                </li>
                <li>
                    <Box style={{display: 'flex', alignItems: 'center'}}>
                        3. {t('preventiveExaminationCard')}
                        {renderFillMark(isPreventiveExaminationCardFilled)}
                    </Box>
                    <Box className={classes.fillLink}>
                        <Link
                            to={`/occupational-medicine/${appointment?.id}`}
                            target="_blank"
                        >
                            {isPreventiveExaminationCardFilled
                                ? t('edit')
                                : t('fill')}
                        </Link>
                    </Box>
                </li>
                <li>
                    <Box style={{display: 'flex', alignItems: 'center'}}>
                        4. {t('additionalMedicalDocumentation')}

                    </Box>
                    <Typography variant={'body2'} style={{
                        marginTop: 4,
                        color: GRAY,
                    }}>{t('additionalMedicalDocumentationSubtitle')}</Typography>
                    <Dropzone
                        className={classes.dropzone}
                        appointmentId={appointment.id}
                    />
                </li>
            </ul>
            <Box style={{marginTop: 32, marginBottom: 8}}>
                {t(
                    'noteDocumentationMustBeCompletedAtLeast30minutesBeforeAppointment',
                )}
            </Box>
            <Loading loading={loading} withBackdrop/>
        </>
    );
};

export default OccupationalMedicineDetails;
